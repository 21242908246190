import { throttle } from "../utils/throttle";

const isTextClamped = elm => elm.scrollHeight > elm.clientHeight;

const checkClamped = (list) => {
    list.forEach((item) => {
        if (window.innerWidth < 768) {
            if (item.querySelector('.ld-news__item').classList.contains('_open')) return
        } else {
            if (item.querySelector('.ld-news__item-big')) return;
        }

        const textBox = item.querySelector('.ld-news__item-txt');

        if (isTextClamped(textBox)) {
            item.classList.add('clamped');
            item.classList.remove('no-clamped');

        } else {
            item.classList.remove('clamped');
            item.classList.add('no-clamped');
        }

    })
}

const clickByOpenButtonHandler = (event) => {
    event.preventDefault();
    const clickBtn = event.target;

    if (window.innerWidth < 768) {
        clickBtn.classList.toggle('active');
        clickBtn.closest('.ld-news__item').classList.toggle('_open');
        clickBtn.setAttribute('aria-label', clickBtn.classList.contains('active') ? clickBtn.getAttribute('data-close-aria-label') : clickBtn.getAttribute('data-open-aria-label'));
    } else {
        const boxNews = clickBtn.parentNode;
        let cloneNews = boxNews.cloneNode(true);
        cloneNews.querySelector('.ld-news__item-open').setAttribute('aria-label', clickBtn.getAttribute('data-close-aria-label'));
        boxNews.insertAdjacentHTML('beforebegin', '<div class="ld-news__item-big"></div>');
        setTimeout(function () {
            const newEl = document.querySelector('.ld-news__item-big')
            newEl.classList.add('active');
            newEl.querySelector('.ld-news__item-open').focus();

            setTimeout(() => {
                newEl.classList.add('opened');
            }, 200)
        }, 100);
        document.querySelector('.ld-news__item-big').append(cloneNews);
        document.querySelector('.ld-news__item-big .ld-news__item').classList.remove('ld-news__item-min');
        document.querySelector('.ld-news__item-big .ld-news__item-open').addEventListener('click', function (e) {
            e.preventDefault();
            document.querySelector('.ld-news__item-big').classList.remove('active', 'opened');
            setTimeout(() => {
                document.querySelector('.ld-news__item-big').remove();
            }, 400);
        });
    }
}
export const mainNewsClamped = () => {
    // Раскрытие блока новости
    const newsList = document.querySelectorAll('.ld-news__span');

    if (!newsList.length) return

    document.addEventListener('DOMContentLoaded', () => checkClamped(newsList));
    window.addEventListener('resize', throttle(() => checkClamped(newsList), 250));
    window.addEventListener('load', () => checkClamped(newsList));

    newsList.forEach((item, i) => {
        const clickBtn = item.querySelector('.ld-news__item-open');

        clickBtn.addEventListener('click', clickByOpenButtonHandler);
    });
}
