export const buttonRipple = () => {
    const body = document.querySelector('body');

    const createRipple = (button, event) => {
        const existRipple = button.getElementsByClassName('ripple');

        if (existRipple.length > 0) button.removeChild(existRipple[0]);

        const circle = document.createElement('div');
        const buttonRect = button.getBoundingClientRect();
        const d = Math.max(buttonRect.height, buttonRect.width);
        button.appendChild(circle);

        Object.assign(circle.style, {
            width: circle.style.height = `${d}px`,
            left: `${event.clientX - buttonRect.x - d / 2}px`,
            top: `${event.clientY - buttonRect.y - d / 2}px`
        });

        circle.classList.add('ripple');
    }

    body.addEventListener('click', (event) => {
        /**
         * @type target {HTMLElement}
         * @type event {Event}
         */
        const {target} = event
        if (body.classList.contains('hover')) return;
        if (target.matches('.button, .ripple-button')) createRipple(target, event);
    });
}
