let unlock = true;
let popupList = document.querySelectorAll('.popup');
let popupLinks = document.querySelectorAll('._popup-link');

for (const popup of popupList) {
    popup.addEventListener("click", function (e) {
        if (!e.target.closest('.popup__body')) {
            popup_close(e.target.closest('.popup'));
        }
    });
}

for (const el of popupLinks) {
    el.addEventListener('click', function (e) {
        e.preventDefault();
        if (unlock) {
            let item = el.getAttribute('href').replace('#', '');
            let video = el.getAttribute('data-video');
            popup_open(item, video);
        }
        e.preventDefault();
    })
}

const body_lock_remove = (delay) => {
    let body = document.querySelector("body");
    if (unlock) {
        let lock_padding = document.querySelectorAll("._lp");
        setTimeout(() => {
            for (const el of lock_padding) {
                el.style.paddingRight = '0px';
            }
            body.style.paddingRight = '0px';
            body.classList.remove("_lock");
        }, delay);

        unlock = false;
        setTimeout(function () {
            unlock = true;
        }, delay);
    }
}

const body_lock_add = (delay) => {
    let body = document.querySelector("body");

    if (unlock) {
        let lock_padding = document.querySelectorAll("._lp");
        for (const el of lock_padding) {
            el.style.paddingRight = window.innerWidth - document.querySelector('.wrapper').offsetWidth + 'px';
        }
        body.style.paddingRight = window.innerWidth - document.querySelector('.wrapper').offsetWidth + 'px';
        body.classList.add("_lock");

        unlock = false;
        setTimeout(function () {
            unlock = true;
        }, delay);
    }
}

const resetFormPopup = (item) => {
    item.querySelectorAll('[type="checkbox"]').forEach((itemCheckbox) => {
        itemCheckbox.checked = false;
    });

    if (document.getElementById("text")) {
        document.getElementById("text").value = '';
        document.getElementById("pos").value = '';
        document.getElementById("allomorph").checked = false;
        document.querySelector('.ld-form__radio-check [value="r"]').checked = true;
    }
}

export const popup_close = (item, bodyUnlock = true) => {
    if (unlock) {
        if (!item) {
            for (const popup of popupList) {
                popup.classList.remove('_active');
            }
        } else {
            item.classList.remove('_active');
            // Чистим попап формы
            resetFormPopup(item);
        }
        if (!document.querySelector('.menu__body._active') && bodyUnlock) {
            body_lock_remove(500);
        }
        history.pushState('', '', window.location.href.split('#')[0]);
    }
}

export const popup_open = (item, video = '') => {
    let activePopup = document.querySelectorAll('.popup._active');
    if (activePopup.length > 0) {
        popup_close('', false);
    }
    let curent_popup = document.querySelector('.popup_' + item);
    if (curent_popup && unlock) {
        if (!document.querySelector('.menu__body._active')) {
            body_lock_add(500);
        }
        if (item === 'video_invitation') {
            document.getElementById("video-html").play();
        }
        curent_popup.classList.add('_active');
        history.pushState('', '', '#' + item);
    }
}
