const toggleButton = '<span class="word-toggle__setting-list"><span class="word-toggle__setting-item ico-st-code"><a href="javascript:void(0);"></a></span></span>'
/**
 * @param node {HTMLElement}
 */
const appendToggleButton = (node) => {
    const lastElementChild = node.lastElementChild;
    let target = lastElementChild;

    if (['UL', 'OL'].includes(lastElementChild.tagName)) {
        target = target.lastElementChild;
    }

    target.insertAdjacentHTML('beforeend', toggleButton)
}

/**
 * @param element {HTMLElement}
 * @param action {string=}
 */
const wordWrapHook = (element, action) => {
    const VNode = document.createElement('div')
    const isOpened = action === '1' || element.getAttribute('data-opened') === '1';

    if (isOpened) {
        VNode.innerHTML = element.getAttribute('data-wrapped-text');
    } else {
        VNode.innerHTML = element.getAttribute('data-origin-text');
    }

    appendToggleButton(VNode);
    element.innerHTML = VNode.innerHTML;
    element.setAttribute('data-opened', isOpened ? '0' : '1');
}

export const wordToggleModule = () => {
    const wordsToggle = document.querySelectorAll('.word-toggle');

    if (wordsToggle.length) {
        wordsToggle.forEach((el) => {
            if (!el.hasAttribute('data-wrapped-text')) return

            el.setAttribute('data-origin-text', el.innerHTML.trim().trim().replace(/\s+/g, ' '));
            wordWrapHook(el, '1');

            el.addEventListener('click', function (e) {
                if (e.target.tagName === 'A' && e.target.closest('.ico-st-code')) {
                    wordWrapHook(el);
                }
            })
        })
    }
}
