import { throttle } from "../utils/throttle";
import Tools from "../utils/tools";

export const onboardingModule = () => {
    /* Онбоардинг */
    const onboarding = document.getElementById('onboarding-module');
    if (!onboarding) return
    Tools.disableScroll();

    const totalCount = Number(onboarding.getAttribute('data-total-count'));
    const listSelector = onboarding.querySelector('.onboarding-module__list');
    const slideButtonSelector = onboarding.querySelector('.onboarding-module__button');
    const counterSelector = onboarding.querySelector('.onboarding-module__counter');
    const closeButtonSelector = onboarding.querySelector('.onboarding-module__close');
    let currentCount = 1;

    const closeOnboarding = () => {
        onboarding.style.opacity = '0';
        Tools.enableScroll();

        fetch(onboarding.getAttribute('data-url')).then(() => {
            onboarding.remove();
            window.removeEventListener('resize', resizeEventHandler)
        });
    }

    const setTransform = (index, animate = false) => {
        const targetTransform = `translateX(${listSelector.getBoundingClientRect().width * index * -1}px)`

        if (animate) {
            listSelector.animate({
                'transform': targetTransform,
                offset: [1, 0],
                easing: ['ease-in', 'ease-out'],
            }, {
                duration: 200,
            }).finished.then(() => {
                listSelector.style.transform = targetTransform;
            })
        } else {
            listSelector.style.transform = targetTransform;
        }
    }

    const resizeEventHandler = throttle(() => {
        setTransform(currentCount - 1, false);
    }, 100)

    closeButtonSelector.addEventListener('click', (event) => {
        event.preventDefault();
        closeOnboarding();
    })

    slideButtonSelector.addEventListener('click', (event) => {
        event.preventDefault();

        if (currentCount < totalCount) {
            setTransform(currentCount, true);
        } else {
            return closeOnboarding();
        }

        if (currentCount === totalCount - 1) {
            slideButtonSelector.textContent = onboarding.getAttribute('data-last-button');
        }

        counterSelector.textContent = `${currentCount + 1} / ${totalCount}`;
        currentCount = currentCount + 1;
    })

    window.addEventListener('resize', resizeEventHandler)

    document.addEventListener('DOMContentLoaded', () => {
        onboarding.focus()
    })
}
