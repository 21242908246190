const Tools = {}

// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
const keys = {37: 1, 38: 1, 39: 1, 40: 1};

function preventDefault(e) {
    e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
    if (keys[e.keyCode]) {
        preventDefault(e);
        return false;
    }
}

// modern Chrome requires { passive: false } when adding event
let supportsPassive = false;
try {
    window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () {
            supportsPassive = true;
        }
    }));
} catch (e) {
}

const wheelOpt = supportsPassive ? {passive: false} : false;
const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
// call this to Disable

Tools.disableScroll = function () {
    window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
    window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
    window.addEventListener('touchm', preventDefault, wheelOpt); // mobile
    window.addEventListener('keydown', preventDefaultForScrollKeys, false);
}

// call this to Enable
Tools.enableScroll = function () {
    window.removeEventListener('DOMMouseScroll', preventDefault, false);
    window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
    window.removeEventListener('touchmove', preventDefault, wheelOpt);
    window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
}

Tools.interpolate = (template, params) => {
    const replaceTags = {'&': '&amp;', '<': '&lt;', '>': '&gt;', '(': '%28', ')': '%29'};
    const safeInnerHTML = text => text.toString().replace(/[&<>\(\)]/g, tag => replaceTags[tag] || tag);
    const keys = Object.keys(params);
    const keyVals = Object.values(params).map(safeInnerHTML);
    return new Function(...keys, `return \`${template.toString().trim()}\``)(...keyVals);
}
/**
 *
 * @param node {HTMLElement}
 * @param icon {string}
 */
const iconsDict = {
    help: '<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 15"><path d="M5.09.5C2.62.5.974 1.845.833 4.358h1.265c.14-2.007 1.165-2.825 2.992-2.825 1.828 0 2.812 1.286 2.812 2.611 0 1.598-.824 2.3-2.049 3.274-1.124.935-1.727 1.442-1.727 3.215v.565h1.265v-.565c0-1.403.362-1.695 1.165-2.319 1.607-1.247 2.61-2.143 2.61-4.17C9.167 1.884 7.4.5 5.09.5Zm.522 14.167v-1.988H3.926v1.988h1.686Z" fill="#1C1C1C"/></svg>',
    keyboard: '<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 12"><rect x=".666" y="1" width="16.667" height="10" rx="1" stroke="#1C1C1C"/><path d="M3.166 6h1.667M3.166 8.5h1.667M3.166 3.5h1.667M8.166 6h1.667M8.166 3.5h1.667M6.5 8.5h5M13.166 6h1.667M13.166 3.5h1.667M13.166 8.5h1.667" stroke="#1C1C1C" stroke-linecap="round"/></svg>',
    double: '<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40"><path d="M20 28V12" stroke="#1C1C1C" stroke-linecap="round"/><circle cx="20" cy="20" r="19.5" stroke="#1C1C1C"/><path d="M14.334 22.778 20 28.445l5.667-5.667" stroke="#1C1C1C" stroke-linecap="round"/></svg>',
    level: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40"><path stroke="#1C1C1C" stroke-linecap="round" d="M17 25V11"/><circle cx="20" cy="20" r="19.5" stroke="#1C1C1C"/><path stroke="#1C1C1C" stroke-linecap="round" d="M27 25H17m6 4.111 4.445-4.444L23 20.222"/></svg>',
    close: '<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40"><circle cx="20" cy="20" r="19.5" stroke="#1C1C1C"/><path d="M27.071 12.929 12.929 27.071M27.071 27.071 12.929 12.929" stroke="#1C1C1C" stroke-linecap="round"/></svg>',
}

Tools.interpolateIcon = (node, icon) => {
    node.insertAdjacentHTML('afterbegin', iconsDict[icon]);
}

Tools.showLoading = () => {
    let loader = document.getElementById('load')

    if (!loader) {
        loader = document.createElement('div')
        loader.setAttribute('id', 'load')
        loader.classList.add('load')
        document.body.appendChild(loader)
    }

    loader.style.display = 'block';
}

Tools.hideLoading = () => {
    document.getElementById('load').style.display = 'none';
}

Tools.elementsIsOverlap = (el1, el2) => {
    const domRect1 = el1.getBoundingClientRect();
    const domRect2 = el2.getBoundingClientRect();

    const isOverlap = !(
        domRect1.top > domRect2.bottom ||
        domRect1.right < domRect2.left ||
        domRect1.bottom < domRect2.top ||
        domRect1.left > domRect2.right
    )

    return [isOverlap, isOverlap ? domRect1.bottom - domRect2.top : 0];
}

Tools.getAbsoluteHeight = (el) => {
    // Get the DOM Node if you pass in a string
    el = (typeof el === 'string') ? document.querySelector(el) : el;

    const styles = window.getComputedStyle(el);
    const margin = parseFloat(styles['marginTop']) +
        parseFloat(styles['marginBottom']);

    return Math.ceil(el.offsetHeight + margin);
}

export default Tools
