export const mainNavModule = () => {
    const header = document.querySelector('.ld-head');

    const showMainNav = () => {
        header.querySelector('.ld-head__menu-all').style.display = 'block';
        document.body.classList.add('body-fix');
    }

    const hideMainNav = () => {
        header.querySelector('.ld-head__menu-all').style.display = 'none';
        document.body.classList.remove('body-fix');
    }

    const showMobileNav = () => {
        header.querySelector('.js-mobile-nav-toggle').classList.add('active');
        header.querySelector('.ld-head__menu-all').style.display = 'block';
        document.body.classList.add('body-fix');
    }

    const hideMobileNav = () => {
        header.querySelector('.js-mobile-nav-toggle').classList.remove('active');
        header.querySelector('.ld-head__menu-all').style.display = 'none';
        document.body.classList.remove('body-fix');
    }

    const toggleMobileNav = () => {
        const button = header.querySelector('.js-mobile-nav-toggle');

        if (button.classList.contains('active')) {
            hideMobileNav()
        } else {
            showMobileNav()
        }
    }

    header.addEventListener('click', (event) => {
        const {target} = event;
        const clsList = target.classList;

        if (target.tagName !== 'BUTTON') return

        switch (true) {
            case clsList.contains('js-open-menu'):
                showMainNav();
                break;
            case clsList.contains('js-close-menu'):
                hideMainNav();
                break;
            case clsList.contains('js-mobile-nav-toggle'):
                toggleMobileNav();
                break;
        }
    }, {passive: true});

    header.querySelector('.ld-head__menu-all .ld-head__wr').addEventListener('click', (event) => {
        /** @type {HTMLElement} */
        const {target} = event;

        if (target.tagName !== 'A') return;

        const parsedHref = new URL(target.href);

        if (window.location.pathname === parsedHref.pathname) {
            hideMainNav();
            hideMobileNav();
        }
    }, {passive: true})
}
