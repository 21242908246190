export const articlePreview = () => {
    const toolbar = document.getElementById('article-toolbar');
    const iframe = document.getElementById('preview-iframe');

    if (!toolbar) return

    const updateIframe = (target) => {
        let options = {'max-height': '100vh'}
        const desktopOptions = {width: '100%', height: '100%'}

        switch (target) {
            case 'desktop':
                Object.assign(options, desktopOptions);
                break
            case 'mobile':
                Object.assign(options, {width: '400px', height: '650px'})
                break
        }

        Object.assign(iframe.style, options)
    }

    toolbar.addEventListener('click', (event) => {
        if (event.target.tagName === 'BUTTON') {
            /**
             * @type button {HTMLElement}
             * @type event {Event}
             */
            const button = event.target;
            const target = button.getAttribute('data-target')

            updateIframe(target);
        }
    });

    // Default is desktop
    updateIframe('desktop');
}
