import { throttle } from "./throttle";

/**
 * Проверяет тип девайса.
 * Добавляет класс к body, указывающий на поддержку hover (если не мобильное устройство)
 */
export const isMobile = () => {
    const isMobileDict = {
        Android: function () {
            return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function () {
            return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function () {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function () {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function () {
            return navigator.userAgent.match(/IEMobile/i);
        },
        any: function () {
            return (isMobileDict.Android() || isMobileDict.BlackBerry() || isMobileDict.iOS() || isMobileDict.Opera() || isMobileDict.Windows());
        }
    };

    const body = document.querySelector('body');
    const markAsMobile = () => body.classList.remove('hover')
    const markAsDesktop = () => body.classList.add('hover')

    if (!isMobileDict.any()) {
        markAsDesktop();
    }
    if (navigator.userAgent.search(/MSIE|Trident|Edge/) >= 0) {
        body.classList.add('ie');
    }
    if (/constructor/i.test(window.HTMLElement) || (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
    })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification))) {
        body.classList.add('io');
    }
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        body.classList.add('io');
    }

    const windowResizeHandle = throttle(() => {
        if (!isMobileDict.any()) {
            markAsDesktop();
        } else {
            markAsMobile();
        }
    }, 200)

    window.addEventListener('resize', windowResizeHandle, {passive: true});
}
