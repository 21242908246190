import '../css/main.scss';

let cloneRow = '';
let cloneRowSize = '';
import { getCookie, deleteCookie, setCookie } from "./modules/cookie";
import { isMobile } from "./utils/is-mobile";
import { buttonRipple } from "./modules/button-ripple";
import { articlePreview } from "./modules/article-preview";
import { _slideToggle, _slideUp, _slideDown } from "./utils/slide-toggle";
import { popup_open, popup_close } from "./modules/popup";
import Tools from "./utils/tools";
import { reachGoal } from "./app/utils/yandex-metrika"

function isValidAnchor(anchor) {
    // Разрешаем буквы, цифры, дефис, подчеркивание и точку в якоре
    var pattern = /^[a-zA-Z0-9\-_.]+$/;
    return pattern.test(anchor);
}

document.addEventListener('DOMContentLoaded', function () {
    document.documentElement.style.setProperty('--scrollbar-width', (window.innerWidth - document.documentElement.clientWidth) + "px");

    setTimeout(() => {
        if (document.querySelector('.ld-load')) {
            document.querySelector('.ld-load').style.display = 'none'
        }
    }, 200);

    isMobile();
    buttonRipple();

    // Найти все ссылки начинающиеся на #
    const anchors = document.querySelectorAll('.ld-article__menu a[href^="#"]')

    // Цикл по всем ссылкам
    for (let anchor of anchors) {

        anchor.addEventListener("click", function (e) {
            e.preventDefault() // Предотвратить стандартное поведение ссылок
            // Атрибут href у ссылки, если его нет то перейти к body (наверх не плавно)
            const goto = anchor.hasAttribute('href') ? anchor.getAttribute('href') : 'body'
            // Плавная прокрутка до элемента с id = href у ссылки
            document.querySelector(goto).scrollIntoView({
                behavior: "smooth",
                block: "start"
            })
        });
    }

    // Меню победитилей
    const tabsBtns = document.querySelectorAll('.dx-winners__tabs a');
    tabsBtns.forEach((tabsBtn, i) => {
        tabsBtn.addEventListener('click', function (e) {
            e.preventDefault();
            tabsBtns.forEach((tabsBtn, i) => {
                tabsBtn.classList.remove('active');
            })
            tabsBtn.classList.add('active');
            visibleEl('.dx-winners__list-item', i);
            visibleEl('.dx-winners__image-item', i, 'flex');
        });
    });

    function visibleEl(name, i, display) {
        document.querySelectorAll(name).forEach((item, index) => {
            let visible = 'none'
            if (i == index) {
                visible = display || 'block'
            }
            item.style.display = visible;
        })
    }

    // Раставляем иконки
    const icoHelp = '<svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.09028 0.5C2.6204 0.5 0.973814 1.84457 0.833252 4.35832H2.09831C2.23887 2.35122 3.26297 1.53278 5.09028 1.53278C6.91759 1.53278 7.90153 2.81889 7.90153 4.14397C7.90153 5.74186 7.07823 6.44338 5.85333 7.4177C4.72883 8.35305 4.12642 8.8597 4.12642 10.633V11.1981H5.39148V10.633C5.39148 9.22994 5.75293 8.93764 6.55614 8.31408C8.16257 7.06694 9.16658 6.17056 9.16658 4.14397C9.16658 1.88354 7.39952 0.5 5.09028 0.5ZM5.61237 14.6667V12.679H3.92562V14.6667H5.61237Z" fill="#1C1C1C"/></svg>';
    const icoKeyboard = '<svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.66626" y="0.999878" width="16.6667" height="10" rx="1" stroke="#1C1C1C"/><path d="M3.16626 5.99988L4.83293 5.99988" stroke="#1C1C1C" stroke-linecap="round"/><path d="M3.16626 8.49988L4.83293 8.49988" stroke="#1C1C1C" stroke-linecap="round"/><path d="M3.16626 3.49988L4.83293 3.49988" stroke="#1C1C1C" stroke-linecap="round"/><path d="M8.16626 5.99988L9.83293 5.99988" stroke="#1C1C1C" stroke-linecap="round"/><path d="M8.16626 3.49988L9.83293 3.49988" stroke="#1C1C1C" stroke-linecap="round"/><path d="M6.49951 8.49988L11.4995 8.49988" stroke="#1C1C1C" stroke-linecap="round"/><path d="M13.1663 5.99988L14.8329 5.99988" stroke="#1C1C1C" stroke-linecap="round"/><path d="M13.1663 3.49988L14.8329 3.49988" stroke="#1C1C1C" stroke-linecap="round"/><path d="M13.1663 8.49988L14.8329 8.49988" stroke="#1C1C1C" stroke-linecap="round"/></svg>';
    const icoDouble = '<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 28L20 12" stroke="#1C1C1C" stroke-linecap="round"/><circle cx="20" cy="20" r="19.5" stroke="#1C1C1C"/><path d="M14.3335 22.7778L20.0002 28.4445L25.6668 22.7778" stroke="#1C1C1C" stroke-linecap="round"/></svg>';
    const icoLevel = '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40"><path stroke="#1C1C1C" stroke-linecap="round" d="M17 25V11"/><circle cx="20" cy="20" r="19.5" stroke="#1C1C1C"/><path stroke="#1C1C1C" stroke-linecap="round" d="M27 25H17M23 29.111l4.445-4.444L23 20.222"/></svg>';
    const icoClose = '<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="20" cy="20" r="19.5" stroke="#1C1C1C"/><path d="M27.071 12.929L12.9289 27.0711" stroke="#1C1C1C" stroke-linecap="round"/><path d="M27.071 27.071L12.9289 12.9289" stroke="#1C1C1C" stroke-linecap="round"/></svg>';
    /*TODO: переписать */
    /*    document.querySelectorAll('._ico-help').forEach((icoBox, i) => {
            icoBox.insertAdjacentHTML('afterbegin', icoHelp);
        });
        document.querySelectorAll('._ico-keyboard').forEach((icoBox, i) => {
            icoBox.insertAdjacentHTML('afterbegin', icoKeyboard);
        });
        document.querySelectorAll('._ico-double').forEach((icoBox, i) => {
            icoBox.insertAdjacentHTML('afterbegin', icoDouble);
        });
        document.querySelectorAll('._ico-level').forEach((icoBox, i) => {
            icoBox.insertAdjacentHTML('afterbegin', icoLevel);
        });
        document.querySelectorAll('._ico-close').forEach((icoBox, i) => {
            icoBox.insertAdjacentHTML('afterbegin', icoClose);
        });*/

    // Перебираем попапы
    const _helpPopup = document.querySelectorAll('.ld-form__help');
    _helpPopup.forEach((_popup, i) => {
        let html = _popup.innerHTML;
        _popup.style.height = 0 + 'px';
        _popup.innerHTML = '<div class="ld-form__help-wr">' + html + '</div>';
        _popup.insertAdjacentHTML('afterend', '<div class="ld-form__help-shadow"></div>');
    });

    document.addEventListener('click', function (e) {
        const target = e.target

        // Открытие блока вопрос
        if (target.closest('._ico-help')) {
            e.preventDefault();
            const _popup = target.nextElementSibling;
            _popup.style.height = _popup.scrollHeight + 10 + 'px';
            _popup.classList.add('_open');
        }
        // Закрытие блока вопрос
        if (target.closest('.ld-form__help-close')) {
            e.preventDefault();
            const _popup = target.closest('.ld-form__help-close').parentNode.parentNode;
            _popup.style.height = 0 + 'px';
            _popup.classList.remove('_open');
        }

        // Скрываем попап вопроса при клике в не его
        if (!target.closest('.ld-form__help') && !target.closest('.ld-form__setting-btn__ico')) {
            document.querySelectorAll('.ld-form__help-close').forEach((_this) => {
                const _popup = _this.parentNode.parentNode;
                _popup.style.height = 0 + 'px';
                _popup.classList.remove('_open');
            });
        }
    })

    // Виртуальная клавиатура
    // https://hodgef.com/blog/simple-keyboard-customizable-responsive-screen-virtual-keyboard-javascript-js-projects/
    if (document.querySelector('.simple-keyboard') && document.querySelector('.popup_keyboard')) {
        import('./modules/keyboard').then(({keyboard: Keyboard}) => {
            const keyboard = Keyboard()

            document.querySelector(".input").addEventListener("input", event => {
                keyboard.setInput(event.target.value);
            });

            document.addEventListener('click', function (e) {
                if (e.target.closest('.js-open-keyboard')) {
                    window.keyboardClick = e.target.closest('.ld-form__label').parentNode.querySelector('.js-input-keyboard');
                    const value = window.keyboardClick.value
                    keyboard.setInput(value);
                    document.querySelector(".input").value = value;
                }
            })
        })

        document.addEventListener('keyup', function (e) {
            if (e.keyCode === 13) {
                if (document.querySelector('.popup_keyboard').classList.contains('_active')) {
                    popup_close();
                }
            }
        });
    }


    // popup
    window.keyboardClick = null;
    let _inputpopup = null

    document.addEventListener('click', function (e) {
        let target = e.target;
        if (target.tagName === 'A') {
            // Клик клонирования

        }
        if (target.closest('.js-open')) {
            const anchor = target.tagName === 'A' ? target.closest('.js-open').getAttribute('href') : target.getAttribute('data-modal')
            popup_open(anchor.replace('#', ''));
            _inputpopup = target.closest('.ld-form__label').parentElement.querySelector('.ld-input');
            let val = _inputpopup.value;
            if (val !== '') {
                let valArrow = [];
                const popupForm = document.querySelector('.popup_' + anchor.replace('#', ''));
                if (target.classList.contains('js-open--reqwf')) {
                    valArrow = val.split('_');
                    let txt = valArrow[0];
                    if (txt !== '') {
                        if (txt.substring(0, 1) === 'q') {
                            txt = txt.substring(1);
                            document.getElementById("allomorph").checked = false;
                        } else {
                            document.getElementById("allomorph").checked = true;
                        }
                        document.getElementById("text").value = txt;
                    }
                    let valRadio = valArrow[1];
                    document.querySelector('.ld-form__radio-check [value="' + valRadio + '"]').checked = true;
                    let pos = valArrow[2];
                    if (pos !== '') {
                        document.getElementById("pos").value = pos;
                    }
                } else {
                    if (target.classList.contains('js-open--line')) {
                        val = val.replace(/,/gm, " ");
                        val = val.trim();
                        valArrow = val.split(' ');
                    } else {
                        val = val.replace(/\),\(/gm, "|");
                        val = val.replace(/\) & \(/gm, " | ");
                        val = val.replace(/ & \(/gm, " | ");
                        val = val.replace(/\)/g, "");
                        val = val.replace(/\(/g, "");
                        val = val.replace(/,/gm, "|");
                        val = val.trim();
                        valArrow = val.split('|');
                    }
                    let index = 0;
                    if (popupForm.querySelector('.popup-checkbox__row-wr.open')) {
                        popupForm.querySelector('.popup-checkbox__row-wr.open').closest('.filter-checkbox').querySelectorAll('[type="checkbox"]').forEach((_checkbox) => {
                            let val = _checkbox.value;
                            if (valArrow[index] == '') {
                                index++
                            }
                            if (valArrow[index] !== undefined) {
                                let nameActive = valArrow[index].trim();
                                if (val == nameActive) {
                                    _checkbox.checked = true;
                                    index++;
                                } else {
                                    if (val.indexOf(nameActive) !== -1) {
                                        for (let step = 0; step < 20; step++) {

                                            if (val.indexOf(valArrow[index].trim()) !== -1) {
                                                _checkbox.checked = true;
                                                index++
                                            } else {
                                                step = 21;
                                            }
                                        }
                                    }
                                }
                            }
                        })
                    }
                }
            }
        }
    });

    function expandAccent(s) {
        if (s == parseInt(s)) {
            return 'à' + s + ' | å' + s + ' | è' + s + ' | î' + s + ' | ó' + s + ' | ý' + s + ' | þ' + s + ' | ÿ' + s;
        }
        if (s.length == 1 && s != parseInt(s)) {
            var res = '';
            for (var i = 1; i <= 10; i++) {
                if (res != '') res += ' | ';
                res += s + i;
            }
            return res;
        }
        return s;
    }

    //=================
    //ActionsOnHash
    if (window.location.hash) {
        const hsh = window.location.hash.replace('#', '');

        if (isValidAnchor(hsh)) {
            if (document.querySelector('.popup_' + hsh)) {
                popup_open(hsh);
            } else if (document.querySelector('div.' + hsh)) {
                _goto(document.querySelector('.' + hsh));
            } else if (document.getElementById(hsh)) {
                _goto(document.getElementById(hsh));
            }
        }
    }

    //=================
    //Popup

    let popup_close_icon = document.querySelectorAll('.popup__close');
    if (popup_close_icon) {
        for (const element of popup_close_icon) {
            const el = element;
            el.addEventListener('click', function (e) {
                e.preventDefault();
                el.closest('.popup').querySelectorAll('.popup-checkbox__header._active').forEach((itemHeader) => {
                    itemHeader.nextElementSibling.classList.remove('_open');
                    itemHeader.nextElementSibling.style.height = 0 + 'px';
                    itemHeader.classList.remove('_active');
                });
                popup_close(el.closest('.popup'));
            })
        }
    }

    // Раскрытие блоков по клику по заголовку
    document.querySelectorAll('.ld-search-form__header').forEach((_this, i) => {
        _this.addEventListener('click', function (e) {
            e.preventDefault();
            if (!_this.classList.contains('_active')) {
                document.querySelectorAll('.ld-search-form__header').forEach((_header) => {
                    _header.classList.remove('_active');
                });
                _this.classList.add('_active');
            }
            setCookie('search_form', i);
        });
    });
    if (getCookie('search_form')) {
        document.querySelectorAll('.ld-search-form__header').forEach((_this, i) => {
            if (getCookie('search_form') == i) {
                _this.classList.add('_active');
            } else {
                _this.classList.remove('_active');
            }
        });
    }
    const _formHead = document.querySelectorAll('.ld-form__head');
    _formHead.forEach((_this) => {
        _this.addEventListener('click', function (e) {
            if (_this.classList.contains('_active')) {
                _this.classList.remove('_active');
            } else {
                document.querySelectorAll('.ld-form__head._active').forEach((item) => {
                    item.classList.remove('_active');
                });
                _this.classList.toggle('_active');
            }

        });
    });

    // Клонирование элементов формы
    if (document.querySelectorAll('.js-el-clone').length > 0) {
        // Клонируем блоки
        const _cloneRow = document.querySelector('.js-el-clone');
        const _cloneRowSize = document.querySelector('.js-el-size-clone');
        // Удаляем класс клонирования
        document.querySelector('.js-el-clone').classList.remove('js-el-clone');
        document.querySelector('.js-el-size-clone').classList.remove('js-el-size-clone');
        cloneRow = _cloneRow.outerHTML;
        cloneRowSize = _cloneRowSize.outerHTML;

        document.addEventListener('click', function (e) {
            let target = e.target;
            // Клик клонирования
            if (target.closest('.js-clone-row')) {
                e.preventDefault();
                let _row = target.closest(".ld-form__row-wr");
                _row.insertAdjacentHTML('afterend', cloneRow);
                _row.insertAdjacentHTML('afterend', cloneRowSize);
                document.querySelector('.js-close-row').style.visibility = 'visible';
                const cloneRowNew = _row.nextElementSibling;
                const cloneRowSizeNew = cloneRowNew.nextElementSibling;
                // Анимация расхлопывания
                slideElement(cloneRowNew);
                slideElement(cloneRowSizeNew);
                // Новые имена для элементов формы
                nameFormNew();
                let allRow = document.querySelectorAll('.ld-form__row-wr');
                let sizeRow = allRow.length - 1;
                const idLex = allRow[sizeRow].querySelector('[data-input-name="lex"]');
                let newName = 'suggest_' + Math.floor(Math.random() * (100 + 1));
                idLex.classList.add(newName);
                idLex.setAttribute('id', newName);
                setSuggest(newName);
            }
            // Клик удаление
            if (target.closest('.js-close-row')) {
                e.preventDefault();
                let rowSize = document.querySelectorAll('.ld-form__row-wr').length;
                if (rowSize > 0) {
                    let _row = target.closest(".ld-form__row-wr");
                    let _rowSizeDelete = _row.previousElementSibling;
                    if (!_rowSizeDelete.classList.contains('ld-form__row-size')) {
                        _rowSizeDelete = _row.nextElementSibling;
                    }
                    if (_rowSizeDelete) {
                        _slideUp(_rowSizeDelete, 200);
                        _rowSizeDelete.classList.add('e-hide-remove2');
                        setTimeout(() => {
                            document.querySelector('.e-hide-remove2').remove();
                        }, 600);

                        nameFormNew();
                    }
                    ;
                    _slideUp(_row, 200);
                    _row.classList.add('e-hide-remove');
                    setTimeout(() => {
                        document.querySelector('.e-hide-remove').remove();
                    }, 600);

                    if (document.querySelector('.ld-form__row-size')) {
                        nameFormNew();
                    }
                }
                if (rowSize < 3) {
                    document.querySelectorAll('a.js-close-row').forEach((item) => {
                        item.style.visibility = 'hidden';
                    })
                }
            }

        });
    }

    // Клонирование элементов формы "Синтаксический корпус"
    if (document.querySelectorAll('.js-level-clone').length > 0) {

        document.querySelectorAll('.ld-form__row-wr, .ld-form__row-size').forEach((item) => {
            item.classList.add('e-hide ');
            item.classList.add('e-hide-open');
            item.style.height = item.clientHeight + 'px';
        });
        // Клонируем блоки
        const _cloneRow = document.querySelector('.js-level-clone');
        // Удаляем класс клонирования
        document.querySelector('.js-level-clone').classList.remove('js-level-clone');
        const cloneRow = _cloneRow.outerHTML;

        document.addEventListener('click', function (e) {
            let target = e.target;
            // Клик клонирования
            if (target.closest('.js-clone-level')) {
                e.preventDefault();
                let _row = target.closest(".js-el-parent");
                _row.insertAdjacentHTML('afterend', cloneRow);
                const cloneRowNew = _row.nextElementSibling;
                let marginLeft = _row.style.marginLeft;
                cloneRowNew.style.marginLeft = marginLeft;
                let sizeParent = _row.querySelector('._input-parent').value;
                let sizeLevel = _row.querySelector('._input-level').value;
                cloneRowNew.querySelector('._input-parent').value = sizeParent;
                cloneRowNew.querySelector('._input-level').value = sizeLevel;
                // Анимация расхлопывания
                slideElement(cloneRowNew);
                // Новые имена для элементов формы
                nameFormNew();
            }
            if (target.closest('.js-clone-level-two')) {
                e.preventDefault();
                let _row = target.closest(".js-el-parent");
                _row.insertAdjacentHTML('afterend', cloneRow);
                const cloneRowNew = _row.nextElementSibling;
                let marginLeft = Number(_row.style.marginLeft.replace('px', "")) + 20;
                cloneRowNew.style.marginLeft = marginLeft + 'px';
                let sizeParent = document.querySelectorAll('._input-parent').length - 1;
                let sizeLevel = Number(_row.querySelector('._input-level').value) + 1;
                cloneRowNew.querySelector('._input-parent').value = sizeParent;
                cloneRowNew.querySelector('._input-level').value = sizeLevel;
                // Анимация расхлопывания
                slideElement(cloneRowNew);
                // Новые имена для элементов формы
                nameFormNew();
            }
            // Клик удаление
            if (target.closest('.js-close-level')) {
                e.preventDefault();
                let rowSize = document.querySelectorAll('.ld-form__row-level').length;
                if (rowSize > 0) {
                    let _row = target.closest(".ld-form__row-level");
                    if (_row) {
                        _row.style.height = 0 + 'px';
                        _row.classList.remove('e-hide-open');
                        _row.classList.add('e-hide-remove2');
                        setTimeout(() => {
                            document.querySelector('.e-hide-remove2').remove();
                        }, 600);

                        nameFormNew();
                    }
                    ;
                    _row.style.height = 0 + 'px';
                    _row.classList.remove('e-hide-open');
                    _row.classList.add('e-hide-remove');
                    setTimeout(() => {
                        let r = document.querySelector('.e-hide-remove');
                        if (r) {
                            r.remove();
                        }
                    }, 600);

                    nameFormNew();
                }
            }

        });
    }

    function slideElement(el) {
        _slideDown(el, 200);
        el.classList.add('e-hide-open');
    }


    // Обновление имен в форме
    function nameFormNew() {
        document.querySelectorAll('.ld-form__row-wr').forEach((_this, index) => {
            _this.querySelectorAll('[data-input-name]').forEach((_input) => {
                let name = _input.getAttribute('data-input-name');
                let indexInput = index + 1;
                let newName = name + indexInput;
                _input.setAttribute('name', newName);
                _input.setAttribute('id', newName);
                // _input.closest('.ld-form__span').querySelector('label').setAttribute('for', newName);
            });
            _this.querySelectorAll('[data-hidden-name]').forEach((_input) => {
                let name = _input.getAttribute('data-hidden-name');
                let indexInput = index + 1;
                let newName = name + indexInput;
                _input.setAttribute('name', newName);
            });
            _this.querySelectorAll('[data-input-name-two]').forEach((_input) => {
                let name = _input.getAttribute('data-input-name-two');
                let indexInput = index + 2;
                let newName = name + indexInput;
                _input.setAttribute('name', newName);
                _input.setAttribute('id', newName);
                _input.closest('.ld-form__span').querySelector('label').setAttribute('for', newName);
            });
            _this.querySelectorAll('[data-checkbox-name]').forEach((_input, indexCheck) => {
                let name = _input.getAttribute('data-checkbox-name');
                let indexInput = index + 1;
                let newName = name + indexInput;
                let newNameId = newName + '_' + indexCheck;
                _input.setAttribute('name', newName);
                _input.setAttribute('id', newNameId);
                _input.nextElementSibling.setAttribute('for', newNameId);
            })
        })
        document.querySelectorAll('.ld-form__row-size').forEach((_this, index) => {
            _this.querySelectorAll('[data-input-name]').forEach((_input) => {
                let name = _input.getAttribute('data-input-name');
                let indexInput = index + 2;
                let newName = name + indexInput;
                _input.setAttribute('name', newName);
                _input.setAttribute('id', newName);
            });
            _this.querySelectorAll('[data-input-name-two]').forEach((_input) => {
                let name = _input.getAttribute('data-input-name-two');
                let indexInput = index + 2;
                let newName = name + indexInput;
                _input.setAttribute('name', newName);
                _input.setAttribute('id', newName);
            });

        })
    }

    nameFormNew()


    // попап "Предметные имена"
    document.querySelectorAll('.js-checkbox-close').forEach((_this) => {
        _this.addEventListener('click', function (e) {
            e.preventDefault();
            _this.closest('form').reset();
        });
    });
    document.querySelectorAll('.js-checkbox-save').forEach((_this) => {
        _this.addEventListener('click', function (e) {
            e.preventDefault();
            const _popup = e.target.closest('.popup');
            const _checkbox = _popup.querySelectorAll('[type="checkbox"]');
            let result = [];
            _checkbox.forEach((item) => {
                if (item.checked) {
                    let n = item.getAttribute('name');
                    if (result[n] == undefined) {
                        result[n] = item.value;
                    } else {
                        result[n] += '|' + item.value
                    }
                }
            });
            let send = '';
            for (let key in result) {
                let i = result[key];
                send += i.indexOf('|') > -1 ? ',(' + i + ')' : ',' + i;
            }
            send = send.substring(1);
            if (_inputpopup) {
                _inputpopup.value = send;
            }
            popup_close(_popup);
        });
    });
    document.querySelectorAll('.js-checkbox-collect-save').forEach((_this) => {
        _this.addEventListener('click', function (e) {
            e.preventDefault();
            const _popup = e.target.closest('.popup');
            const _checkbox = _popup.querySelectorAll('[type="checkbox"]');
            let result = [];
            _checkbox.forEach((item) => {
                if (item.checked) {
                    let n = item.getAttribute('name');
                    if (result[n] == undefined) {
                        result[n] = item.value;
                    } else {
                        result[n] += ' | ' + item.value
                    }
                }
            });
            let send = '';
            for (let key in result) {
                let i = result[key];
                send += ' | ' + i;
            }

            send = send.substring(3);
            if (_inputpopup) {
                _inputpopup.value = send;
            }
            popup_close(_popup);
        });
    });
    document.querySelectorAll('.js-checkbox-reqsem-save').forEach((_this) => {
        _this.addEventListener('click', function (e) {
            e.preventDefault();
            const _popup = e.target.closest('.popup');
            const _checkbox = _popup.querySelector('.popup-checkbox__row-wr.open').closest('.filter-checkbox').querySelectorAll('[type="checkbox"]');
            let result = [];
            _checkbox.forEach((item) => {
                if (item.checked || item.classList.contains('_no_reset')) {
                    let n = item.getAttribute('name');
                    if (result[n] == undefined) {
                        result[n] = item.value;
                    } else {
                        result[n] += ' | ' + item.value
                    }
                }
            });
            let send = '';
            let gr = '';
            for (let key in result) {
                let i = result[key];
                if (i.indexOf(' ') != -1) i = '(' + i + ')'
                if (key == 'gr') gr = i;
                else {
                    if (send) send += ' & ' + i;
                    else send = i;
                }
            }

            if (_inputpopup) {
                _inputpopup.value = send;
            }
            popup_close(_popup);
        });
    });
    document.querySelectorAll('.js-checkbox-additional-save').forEach((_this) => {
        _this.addEventListener('click', function (e) {
            e.preventDefault();
            const _popup = e.target.closest('.popup');
            const _checkbox = _popup.querySelectorAll('[type="checkbox"]');
            let result = [];
            _checkbox.forEach((item) => {
                if (item.checked) {
                    let n = item.getAttribute('name');
                    if (result[n] == undefined) {
                        result[n] = item.value;
                    } else {
                        result[n] += ' ' + item.value
                    }
                }
            });
            let send = '';
            for (let key in result) {
                let i = result[key];
                if (i.indexOf('|') != -1) i = '(' + i + ')'
                send += ',' + i;
            }
            send = send.substring(1);
            if (_inputpopup) {
                _inputpopup.value = send;
            }
            popup_close(_popup);
        });
    });
    document.querySelectorAll('.js-form-collect-save').forEach((_this) => {
        _this.addEventListener('click', function (e) {
            e.preventDefault();
            const _popup = e.target.closest('.popup');
            _inputpopup.value = collect();
            popup_close(_popup);
        });
    });


    // Развернуть/свернуть
    const _subsetting = document.querySelector('.ld-search__subsetting-filter');
    const _inquiry = document.querySelector('.ld-search__inquiry-filter');
    document.querySelectorAll('.js-el-toggle').forEach((_this) => {
        _this.addEventListener('click', function (e) {
            e.preventDefault();
            _slideToggle(_subsetting, 200);
            _slideToggle(_inquiry, 200);
            tooltipRemove();
            tooltipInit(e);
            e.target.classList.toggle('active');
            if (e.target.classList.contains('active')) {
                setCookie('search_legend', 1);
            } else {
                deleteCookie('search_legend');
            }
        });
    });

    // Закрытие при тапе
    document.onmouseout = (e) => {
        tooltipRemove();
    };
    // Закрытие при перетягивании
    window.onscroll = () => {
        if (document.querySelector('.tooltip')) {
            tooltipRemove();
        }
    }

    // попап "Предметные имена"
    document.querySelectorAll('.js-checkbox-close').forEach((_this) => {
        _this.addEventListener('click', function (e) {
            e.preventDefault();
            _this.closest('form').reset();
        });
    });

    // Фильтр на страничке результатов поиска
    document.querySelectorAll('.ld-st__ico').forEach((_this) => {
        _this.addEventListener('click', function (e) {
            e.preventDefault();
            if (!_this.parentNode.classList.contains('open')) {
                document.querySelectorAll('.ld-st__ico').forEach((_item) => {
                    _item.parentNode.classList.remove('open');
                });
                document.querySelectorAll('.ld-st__popup').forEach((_item) => {
                    _item.style.height = 0 + 'px';
                });
            }
            _this.parentNode.classList.toggle('open');
            const _popup = _this.parentNode.querySelector('.ld-st__popup');
            let h = _popup.scrollHeight;
            if (window.innerWidth < 768) {
                h = window.innerHeight;
                document.querySelector('.ld-result__list').classList.add('_el-hide');
            }
            _popup.style.height = h + 'px';
        });
    });
    document.querySelectorAll('.ld-st__popup-close, .ld-st__popup-close-ico').forEach((_this) => {
        _this.addEventListener('click', function (e) {
            e.preventDefault();
            _this.closest('.ld-st').classList.remove('open');
            const _popup = _this.closest('.ld-st').querySelector('.ld-st__popup');
            _popup.style.height = 0 + 'px';
            document.querySelector('.ld-result__list').classList.remove('_el-hide');
        });
    });
    document.addEventListener('mousedown', function (e) {
        if (e.target.closest('.ld-st') === null) {
            document.querySelectorAll('.ld-st').forEach((_this) => {
                _this.closest('.ld-st').classList.remove('open');
                const _popup = _this.closest('.ld-st').querySelector('.ld-st__popup');
                _popup.style.height = 0 + 'px';
            });
        }
    });

    // Авторизация
    const autorizeTab = document.querySelectorAll('.ld-autorize__tab a');
    autorizeTab.forEach((_this, i) => {
        _this.addEventListener('click', function (e) {
            e.preventDefault();
            autorizeTab.forEach((tabsBtn, i) => {
                tabsBtn.classList.remove('active');
            });
            _this.classList.add('active');
            const tabItems = document.querySelectorAll('.ld-autorize__item');
            tabItems.forEach((tabsItem, eq) => {
                let visible = 'none'
                if (i == eq) {
                    visible = 'block'
                }
                tabsItem.style.display = visible
            });
        });
    });

    // Валидация форм
    let btnSubmit = document.querySelectorAll('.js-submit');
    btnSubmit.forEach((_this, i) => {
        _this.addEventListener('click', function (e) {
            e.preventDefault();
            form_submit(e);
        });
    });

    async function form_submit(e) {
        let btn = e.target;
        let form = btn.closest('form');
        let error = form_validate(form);
        if (error == 0) {
            let formAction = form.getAttribute('action') ? form.getAttribute('action').trim() : '#';
            let formMethod = form.getAttribute('method') ? form.getAttribute('method').trim() : 'GET';
            //SendForm
            if (formAction.length > 2) {
                e.preventDefault();
                let formData = new FormData(form);
                form.classList.add('_sending');

                fetch(formAction, {
                    method: formMethod,
                    body: formData
                })
                    .then(response => response.json())
                    .then(response => {
                        form.classList.remove('_sending');
                        document.querySelectorAll('.popup_thanks__sabtitile').textContent = response.order_number;
                        popup_open('thanks');
                    })
                    .catch(err => {
                        form.classList.remove('_sending');
                    })
            }
        } else {
            let form_error = form.querySelectorAll('._error');
            if (form_error && form.classList.contains('_goto-error')) {
                _goto(form_error[0]);
            }
            e.preventDefault();
        }
    }

    function form_validate(form) {
        let error = 0;
        let form_req = form.querySelectorAll('._req');
        if (form_req.length > 0) {
            for (let index = 0; index < form_req.length; index++) {
                const el = form_req[index];
                if (!_is_hidden(el)) {
                    error += form_validate_input(el);
                }
            }
        }
        return error;
    }

    function form_validate_input(input) {
        let error = 0;
        let input_g_value = input.getAttribute('data-value');

        if (input.getAttribute("name") == "email" || input.classList.contains("_email")) {
            if (input.value != input_g_value) {
                let em = input.value.replace(" ", "");
                input.value = em;
            }
            if (email_test(input) || input.value == input_g_value) {
                form_add_error(input);
                error++;
            } else {
                form_remove_error(input);
            }
        } else if (input.classList.contains("_repeat")) {
            if (input.value != input_g_value) {
                let em = input.value.replace(" ", "");
                input.value = em;
            }
            if (!repeat_test(input) || input.value == input_g_value) {
                form_add_error(input);
                error++;
            } else {
                form_remove_error(input);
            }
        } else if (input.getAttribute("type") == "checkbox" && input.checked == false) {
            form_add_error(input);
            error++;
        } else {
            if (input.value == '' || input.value == input_g_value) {
                form_add_error(input);
                error++;
            } else {
                form_remove_error(input);
            }
        }
        return error;
    }

    function form_add_error(input) {
        input.classList.add('_error');
        input.parentElement.classList.add('_error');

        let input_error = input.parentElement.querySelector('.form__error');
        if (input_error) {
            input.parentElement.removeChild(input_error);
        }
        let input_error_text = input.getAttribute('data-error');
        if (input_error_text && input_error_text != '') {
            input.parentElement.insertAdjacentHTML('beforeend', '<div class="form__error"><div class="form__error-tooltip"><span>' + input_error_text + '</span></div></div>');
        }
    }

    function form_remove_error(input) {
        input.classList.remove('_error');
        input.parentElement.classList.remove('_error');

        let input_error = input.parentElement.querySelector('.form__error');
        if (input_error) {
            input.parentElement.removeChild(input_error);
        }
    }


    //IsHidden
    function _is_hidden(el) {
        return (el.offsetParent === null)
    }

    //email_test
    function email_test(input) {
        return !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/.test(input.value);
    }

    //repeat_test
    function repeat_test(input) {
        const passName = input.getAttribute("data-repeat");
        const pass = input.closest('form').querySelector(passName);
        let valPass = pass.value;
        let valPAssRepeat = input.value;
        return valPass == valPAssRepeat;
    }

    // goto
    function _goto(target_block, behavior = "smooth", block = "start") {
        target_block.scrollIntoView({ behavior: behavior, block: block })
    }

    // меню мобильн
    const _concordanceHead = document.querySelector('.ld-result__concordance-head');
    if (_concordanceHead) {
        _concordanceHead.addEventListener('click', function (e) {
            e.preventDefault();
            if (window.innerWidth < 768) {
                _concordanceHead.classList.toggle('active');
                let visible = _concordanceHead.classList.contains('active') ? 'block' : 'none';
                document.querySelector('.ld-result__concordance-body').style.display = visible
            }
        });
    }
    ;

    // submit формы при клике на ссылку
    let btnSubmitForm = document.querySelectorAll('.js-submit-form');
    btnSubmitForm.forEach((_this, i) => {
        setCookie('search_save', 1);
        _this.addEventListener('click', function (e) {
            e.preventDefault();
            _this.closest('form').submit();
        });
    });

    // submit формы подкорпуса
    let btnSubmitSubcorpus = document.querySelectorAll('.js-submit-subcorpus');
    btnSubmitSubcorpus.forEach((item, i) => {
        item.addEventListener('click', function (e) {
            e.preventDefault();
            deleteCookie('subcorpus_options');
            subcorpusCookie(item);
            document.querySelector('#subcorpus-filter').submit();
        });
    });

    // Добавление формы в куки
    function subcorpusCookie(item) {
        let formArrow = {};
        item.closest('form').querySelectorAll('input').forEach((input, i) => {
            let inpName = input.getAttribute('name');
            let value = input.value;
            if (input.getAttribute('type') == 'checkbox') {
                if (!input.checked) {
                    value = '';
                    if (inpName !== 'doc_sphere') return false;
                }
            }

            let arrowItem = formArrow[inpName];
            if (Array.isArray(arrowItem)) {
                formArrow[inpName].push(value)
            } else {
                if (arrowItem) {
                    let a = formArrow[inpName];
                    formArrow[inpName] = [a];
                    formArrow[inpName].push(value);
                } else {
                    formArrow[inpName] = value;
                }
            }
        });
        item.closest('form').querySelectorAll('select').forEach((select, i) => {
            formArrow[select.getAttribute('name')] = select.value;
        });
        // Добавляем в куки форму подкорпуса
        setCookie('subcorpus_options', formArrow);
    }


    // Записываем ссылку куда вернуться после выбора подкорпуса
    let dataBack = document.querySelectorAll('.js-back-url');
    dataBack.forEach((item, i) => {
        let url = window.location.href.replace(/doc_author=[^&]*/, "");
        setCookie('url_back', url);
    });
    let btnBackSubcorpus = document.querySelectorAll('.js-back-subcorpus');
    btnBackSubcorpus.forEach((item, i) => {
        if (getCookie('url_back')) {
            item.setAttribute('href', getCookie('url_back'));
        }
        item.addEventListener('click', function (e) {
            subcorpusCookie(item);
        });
    });

    // Очистка поиска подкорпуса
    let btnRemoveSubcorpus = document.querySelectorAll('.js-remove-subcorpus');
    btnRemoveSubcorpus.forEach((item, i) => {
        item.addEventListener('click', function (e) {
            e.preventDefault();
            deleteCookie('subcorpus_stat');
            deleteCookie('subcorpus_options');

            if (document.querySelector('.ld-result__head')) {
                if (document.querySelector('.ld-result__head').style.display !== 'flex') {
                    window.location.href = '/subcorpus_select.html';
                    return false;
                }
            }

            let subcorpusArrow = ['format', 'doc_i_tagging', 'doc_subcorpus', 'doc_author', 'search-name_text', 'doc_i_ge_birthday_e', 'doc_i_le_birthday_s', 'doc_i_ge_end_year', 'doc_i_le_start_year', 'doc_sex', 'doc_genre_fi', 'doc_type', 'doc_chronotop', 'doc_sphere', 'doc_type', 'doc_topic'];
            let urlArrow = queryStringArray();
            for (let i in subcorpusArrow) {
                delete urlArrow[subcorpusArrow[i]]
            }
            let url = '';
            for (let i in urlArrow) {
                url += '&' + i + '=' + urlArrow[i]
            }
            url = url.substr(1);
            if (url !== '') {
                renderResults(url);
            }
            if (document.querySelector('#search-filter')) {
                show('.ld-search__subsetting-search-no');
                hide('.ld-search__subsetting-search-ok');
                document.querySelector('.ld-search__subsetting-info-size').innerHTML = '';
            }

            document.querySelector('.ld-search__subsetting-filter').innerHTML = '';
            dispatcher();
        });
    });

    // очистка Cookie формы подкорпуса
    let btnResetSubcorpus = document.querySelectorAll('.js-subcorpus-reset');
    btnResetSubcorpus.forEach((item, i) => {
        item.addEventListener('click', function (e) {
            deleteCookie('subcorpus_options');
        });
    });


    // раскрытие вкладки фильтр
    function filterTabs() {
        const checkForm = document.querySelectorAll('form');
        checkForm.forEach((form, i) => {
            let HTMLlnk = '';
            let HTMLheader = '';
            let btnHeadCheck = form.querySelectorAll('.popup-checkbox__head');
            btnHeadCheck.forEach((item, i) => {
                if (i == 0) {
                    item.classList.add('open');
                    item.nextElementSibling.classList.add('open');
                    item.nextElementSibling.style.height = item.nextElementSibling.scrollHeight + 'px';
                } else {
                    item.nextElementSibling = 0 + 'px';
                }
                if (btnHeadCheck.length > 1) {
                    if (i == 0) {
                        HTMLheader = item.textContent;
                    }
                    HTMLlnk += '<li><a href="">' + item.textContent + '</a></li>';
                }
            });
            if (btnHeadCheck.length == 1) {
                btnHeadCheck[0].style.display = 'none';
            }
            if (HTMLlnk !== '') {
                HTMLlnk = '<div class="popup-checkbox__menu">' +
                    '<a href="" class="popup-checkbox__menu-open"><span>' + HTMLheader + '</span></a>' +
                    '<ul class="popup-checkbox__menu-list" style="height: 0px">' + HTMLlnk + '</ul>';
                form.insertAdjacentHTML('afterbegin', HTMLlnk);

                const openBtn = form.querySelector('.popup-checkbox__menu-open');
                const openList = form.querySelector('.popup-checkbox__menu-list');
                openBtn.addEventListener('click', function (e) {
                    e.preventDefault();
                    if (openBtn.classList.contains('open')) {
                        openBtn.classList.remove('open');
                        openList.style.height = 0 + 'px';
                        openList.classList.remove('open');
                    } else {
                        openBtn.classList.add('open');
                        openList.style.height = openList.scrollHeight + 'px';
                        openList.classList.add('open');
                    }
                });
                openList.querySelectorAll('a').forEach((item, i) => {
                    item.addEventListener('click', function (e) {
                        e.preventDefault();
                        openBtn.querySelector('span').textContent = item.textContent;
                        openBtn.classList.remove('open');
                        openList.style.height = 0 + 'px';
                        openList.classList.remove('open');
                        let _row = item.closest('form').querySelectorAll('.popup-checkbox__row-wr.open');
                        _row.forEach((item, i) => {
                            item.classList.remove('open');
                            item.style.height = 0 + 'px';
                            item.querySelectorAll('input').forEach((ch, i) => {
                                ch.checked = false;
                            });
                        });
                        _filterOpen = form.querySelectorAll('.filter-checkbox')[i].querySelector('.popup-checkbox__row-wr');
                        _filterOpen.classList.add('open');
                        _filterOpen.style.height = _filterOpen.scrollHeight + 'px';
                    });
                });
            }
        });

    }

    function inverse() {
        const headerInverse = document.querySelectorAll('[data-inverse]');
        headerInverse.forEach((item, i) => {
            item.addEventListener('click', function (e) {
                if (window.innerWidth > 767) {
                    let group = item.getAttribute('data-inverse');
                    document.querySelectorAll('[name="' + group + '"]').forEach((item, i) => {
                        item.checked = !item.checked;
                    });
                }
            });

        });
    }


    // генерируем фильтр
    const _jsonFilter = document.querySelector('.json-filter');
    if (_jsonFilter) {
        const url = _jsonFilter.getAttribute('data-url');

        var request = new XMLHttpRequest();
        request.open('GET', url, true);

        request.onload = function () {
            if (this.status >= 200 && this.status < 400) {
                // при успешном подключении
                var resp = this.response;
                var data = JSON.parse(this.response);
                buildFilter(data);
                filterTabs();
                // inverse();
            } else {
                // подключились к серверу, но он вернул ошибку

            }
        };

        request.send();
    }

    function buildFilter(data) {
        document.querySelectorAll('.filter-checkbox').forEach((item, i) => {
            let id = item.getAttribute('data-code');
            let dataItem = data[id];
            let dataColumns = dataItem.columns;
            let HTML = '';
            for (let key in dataColumns) { // перебираем колонки
                let dataColumn = dataColumns[key];
                let HTMLcolumn = '';
                for (let i in dataColumn) { // перебираем разделы
                    let dataList = dataColumn[i].list;
                    let HTMLlist = '';
                    for (let ii in dataList) { // перебираем чекбоксы
                        let HTMLlist2 = '';
                        if (dataList[ii].level) {
                            let dataLevel2 = dataList[ii].level;
                            for (let iii in dataLevel2) { // перебираем чекбоксы второго уровня
                                let dataLevel3 = dataLevel2[iii].level;
                                let HTMLlist3 = '';
                                if (dataLevel2[iii].level) {
                                    for (let iiii in dataLevel3) { // перебираем чекбоксы третьего уровня
                                        HTMLlist3 += checkItemBuild(dataLevel3[iiii], '');
                                    }
                                    HTMLlist3 = '<ul>' + HTMLlist3 + '</ul>';
                                }
                                HTMLlist2 += checkItemBuild(dataLevel2[iii], HTMLlist3);
                            }
                            HTMLlist2 = '<ul>' + HTMLlist2 + '</ul>'
                        }
                        HTMLlist += checkItemBuild(dataList[ii], HTMLlist2);
                    }
                    let listHeader = '';
                    if (dataColumn[i].code && dataColumn[i].code.length > 0) {
                        listHeader = '<span data-inverse="' + dataColumn[i].code + '">' + dataColumn[i].header + '</span>';
                    } else {
                        listHeader = '<span>' + dataColumn[i].header + '</span>';
                    }
                    HTMLcolumn += '<div class="popup-checkbox__header">' +
                        listHeader +
                        '</div>' +
                        '<ul class="popup-checkbox__list">' + HTMLlist + '</ul>';
                    if (dataColumn[i].legend) {
                        HTMLcolumn += `<div class="popup-checkbox__legend">${dataColumn[i].legend}</div>`;
                    }
                }
                HTML += '<div class="popup-checkbox__span">' + HTMLcolumn + '</div>';
            }
            if (dataItem.head && dataItem.head.length > 0) {
                HTML = '<div class="popup-checkbox__head">' +
                    '<span>' + dataItem.head + '</span>' +
                    '</div>' +
                    '<div class="popup-checkbox__row-wr" style="height: 0px;"><div class="popup-checkbox__row">' +
                    HTML +
                    '</div></div>';
            } else {
                HTML = '<div class="popup-checkbox__row-wr open"><div class="popup-checkbox__row">' +
                    HTML +
                    '</div></div>';
            }
            let inputAncor = item.getAttribute('data-ancor');
            let inputAncorName = item.getAttribute('data-ancor-name');
            if (inputAncor) {
                HTML = '<input type="checkbox" name="' + inputAncorName + '" value="' + inputAncor + '" id="' + inputAncor + '" checked="checked" class="_no_reset" style="display: none">' + HTML;
            }
            item.innerHTML = HTML;
            if (item.querySelectorAll('.popup-checkbox__list').length == 1) {
                item.querySelector('.popup-checkbox__header').classList.add('_active');
                item.querySelector('.popup-checkbox__list').classList.add('_open');
            }

        });
        // Отмечаем вложенные чекбоксы
        const btnCheckbox = document.querySelectorAll('.popup-checkbox__list label');
        btnCheckbox.forEach((item, i) => {
            item.addEventListener('click', function (e) {
                let _level = item.parentNode.querySelector('ul');
                if (_level) {
                    let _input = item.previousElementSibling;
                    let statusCheck = true
                    if (_input.checked) {
                        statusCheck = false
                    }
                    _level.querySelectorAll('input').forEach((itemCheck) => {
                        itemCheck.checked = statusCheck
                    });
                }
            });
        });

        // Попап выбора на мобильном
        const _popupСheckboxHead = document.querySelectorAll('.popup-checkbox__header');
        if (_popupСheckboxHead) {
            _popupСheckboxHead.forEach((_this) => {
                _this.addEventListener('click', function (e) {
                    if (window.innerWidth < 768) {
                        let _next = _this.nextElementSibling;
                        if (!_this.classList.contains('_active')) {
                            _this.closest('.popup').querySelectorAll('.popup-checkbox__header._active').forEach((itemHeader) => {
                                itemHeader.nextElementSibling.classList.remove('_open');
                                itemHeader.nextElementSibling.style.height = 0 + 'px';
                                itemHeader.classList.remove('_active');
                            });
                            _this.classList.toggle('_active');
                            _next.classList.add('_open');
                            _next.style.height = _next.scrollHeight + 'px';
                        } else {
                            _this.classList.remove('_active');
                            _next.classList.remove('_open');
                            _next.style.height = 0 + 'px';
                        }
                    } else {
                        _this.nextElementSibling.querySelectorAll('[type="checkbox"]').forEach((item) => {
                            item.checked = !item.checked;
                        });
                    }
                });
            });
        }
        ;
    }

    var checId = 0;

    function checkItemBuild(data, HTMLfind) {
        if (data) {
            checId++;
            let title = data.title ? data.title : '';
            if (HTMLfind === '<ul></ul>') {
                HTMLfind = ''
            }
            return '<li>' +
                '<input type="checkbox" id="' + data.name + '-' + checId + '" name="' + data.name + '" value="' + data.value + '">' +
                '<label title="' + title + '" for="' + data.name + '-' + checId + '">' + data.txt + '</label>' +
                HTMLfind +
                '</li>';
        }
    }

    document.querySelectorAll('.change-lang').forEach(el => {
        el.addEventListener('click', () => {
            const form = document.getElementById('set-land-form');
            const next = form.querySelector('input[name="next"]');
            const newLang = form.querySelector('input[name="language"]').value
            
            if (newLang === "en") {
                reachGoal('TARGET_ENG')
            } else {
                reachGoal('TARGET_RUS')
            }

            if (window.location.search && next) {
                next.value = `${next.value}${window.location.search}`
            }
            setTimeout(() => form.submit(), 50);
        })
    });

    (() => {
        // Проверка перекрытия меню на статических страницах
        const staticPage = document.getElementById('static-page');

        if (staticPage && window.innerWidth >= 768) {
            const sections = Array.from(staticPage.querySelectorAll('.ld-article__row'))
            const menu = sections.length >= 1 ? sections[0].querySelector('.ld-article__menu') : null
            if (menu) {
                const wrapper = menu.closest('.ld-article__wr');
                const header = wrapper.querySelector('.ld-article__header');
                wrapper.style.minHeight = `${Tools.getAbsoluteHeight(menu) + Tools.getAbsoluteHeight(header) + 40}px`;
            }
            if (sections.length < 2) return
            const nextAsideBlock = sections[1].querySelector('.ld-article__additionally');
            if (menu && nextAsideBlock) {
                const [isOverlap, overlapSize] = Tools.elementsIsOverlap(menu, nextAsideBlock);

                if (isOverlap) {
                    nextAsideBlock.style.marginTop = `${overlapSize}px`
                }
            }

        }
    })();

    const hasMessages = document.getElementById('static-messages');

    if (hasMessages) {
        document.body.addEventListener('click', (event) => {
            const {target} = event;
            const staticMessagesWrapper = target.closest('#static-messages')
            if (target.tagName === "BUTTON" && staticMessagesWrapper) {

                target.closest('li').remove();
                if (!staticMessagesWrapper.querySelectorAll('li').length) {
                    staticMessagesWrapper.remove()
                }
            }
        })
    }
});

// tooltip
let tooltipElem;
document.onmouseover = function (event) {
    tooltipInit(event)
};

function tooltipInit(event, text) {
    let target = event.target;

    // если у нас есть подсказка...
    let tooltipHtml = target.dataset.tooltip;
    if (!tooltipHtml && text == undefined) return;

    if (target.closest(".active")) {
        tooltipHtml = target.dataset.tooltip_active;
    }


    // ...создадим элемент для подсказки
    tooltipElem = document.createElement('div');
    tooltipElem.className = 'tooltip';
    if (target.closest(".ico-homonymy-no")) {
        tooltipElem.className = 'tooltip tooltip-homonymy-no';
    }
    if (target.closest(".ico-homonymy-ok")) {
        tooltipElem.className = 'tooltip tooltip-homonymy-ok';
    }

    if (text == undefined) {
        tooltipElem.innerHTML = tooltipHtml;
    } else {
        tooltipElem.innerHTML = text;
    }

    if (tooltipElem == undefined) return false
    document.body.append(tooltipElem);

    // спозиционируем его сверху от аннотируемого элемента (top-center)
    let coords = target.getBoundingClientRect();

    let left = coords.left + (target.offsetWidth / 2 - tooltipElem.offsetWidth) + 14;
    if (left < 0) left = 0; // не заезжать за левый край окна

    let top = coords.top - tooltipElem.offsetHeight - 14;
    if (top < 0) { // если подсказка не помещается сверху, то отображать её снизу
        top = coords.top + target.offsetHeight + 14;
    }

    if (target.closest(".ico-homonymy-no") || target.closest(".ico-homonymy-ok")) {
        left = coords.left;

        if ((left + tooltipElem.offsetWidth) > window.innerWidth) { // не заезжать за правый край окна
            left = window.innerWidth - tooltipElem.offsetWidth;
        }
    }

    console.log(left);

    tooltipElem.style.left = left + 'px';
    tooltipElem.style.top = top + 'px';
}

function tooltipRemove() {
    if (tooltipElem) {
        tooltipElem.remove();
        tooltipElem = null;
    }
}

function toggleStrictDate(name_start, name_end, state) {
    var elem1 = document.getElementById(name_start);
    var elem2 = document.getElementById(name_end);
    if (state) {
        elem1.name = name_start + "_x";
        elem2.name = name_end + "_x";
    } else {
        elem1.name = name_start;
        elem2.name = name_end;
    }
}

function inverse(group) {
    if (window.innerWidth > 767) {
        document.querySelectorAll('[name="' + group + '"]').forEach((item, i) => {
            item.checked = !item.checked;
        });
    }
}

function toggle_text_under_checkbox(node) {
    node.closest('.ld-form__row').querySelectorAll('.ld-input-disabled').forEach((item, i) => {
        item.disabled = !item.disabled;
    });
}


function collect() {
    let s = '';
    let allomorph = document.getElementById('allomorph').checked;
    let text = document.getElementById('text').value;
    if (!allomorph && text !== "") s += "q";
    s += text;
    s += "_";

    const types = window.document.getElementsByName('type');
    for (var i = 0; i < types.length; ++i)
        if (types[i].checked) {
            s += types[i].value;
            break;
        }
    s += "_";
    s += window.document.getElementById('pos').value;
    return s;
}

function hidePopup() {
    document.querySelector('.ld-st').classList.remove('open');
    const _popup = document.querySelector('.ld-st__popup');
    _popup.style.height = 0 + 'px';
    document.querySelector('.ld-result__list').classList.remove('_el-hide');
}


function dropdown(name) {
    document.querySelectorAll(name).forEach((item) => {
        if (!item.classList.contains('init')) {
            item.classList.add('init');
            const body = item.querySelector('.ld-dropdown__body');
            const head = item.querySelector('.ld-dropdown__head');
            head.addEventListener('click', (e) => {
                e.preventDefault();
                _slideToggle(body, 200);
                e.target.parentElement.classList.toggle('_active');
            })
            item.querySelectorAll('.ld-dropdown__item').forEach((option) => {
                option.addEventListener('click', (e) => {
                    e.preventDefault();
                    _slideUp(body, 200);
                    head.classList.remove('_active');
                    let id = option.getAttribute('value');
                    document.querySelectorAll('.dropdown-element').forEach((dropEl) => {
                        if (dropEl.getAttribute('id') == id) {
                            _slideDown(dropEl, 0);
                        } else {
                            _slideUp(dropEl, 0);
                        }
                    });
                    let name = option.textContent;
                    head.querySelector('span').textContent = name;
                })
            });
        }
    });
}

document.addEventListener('click', function (e) {
    if (document.querySelectorAll('.ld-dropdown').length) {
        if (!e.target.closest('.ld-dropdown')) {
            _slideUp(document.querySelector('.ld-dropdown__body'), 200)
        }
    }
});

const menuLinkSearch = document.querySelector('.ld-head__menu-ico a');
if (menuLinkSearch) {
    document.querySelector('.ld-head__menu-ico a').addEventListener('click', (e) => {
        deleteCookie('search_options')
    });
}

document.body.addEventListener('click', (event) => {
    const {target} = event;

    if (target.tagName === 'BUTTON' && target.classList.contains('button--icon')) {
        target.classList.toggle('open');

        if (target.classList.contains('open')) {
            target.textContent = target.getAttribute('data-active-text')
        } else {
            target.textContent = target.getAttribute('data-origin-text')
        }
    }
})

import { mainNewsClamped } from "./modules/main-news-clamped";
import { onboardingModule } from "./modules/onbroading";
import { wordToggleModule } from "./modules/word-toggle";
import { mainNavModule } from "./modules/main-nav";
import { expandMenu } from "./modules/expand-menu";

/** @type HTMLFormElement */
const mainSearchToExplore = document.getElementById('main-search-to-explore')

if (mainSearchToExplore) {
    const reqInput = mainSearchToExplore.querySelector('input[name="req"]')
    let attemptSubmit = false

    reqInput.addEventListener('input', () => {
        if (!attemptSubmit) return
        attemptSubmit = false
        reqInput.setCustomValidity('');
    })

    mainSearchToExplore.addEventListener('submit', (event) => {
        attemptSubmit = true
        const value = reqInput.value.replace(/\s\s+/g, ' ').trim();
        const regExp = /[\&\(\)\|\*]|\s-\S|^-/g
        if (regExp.exec(value) !== null) {
            reqInput.setCustomValidity(reqInput.getAttribute('data-complex-query-message'));
            event.preventDefault()
        } else {
            reqInput.setCustomValidity('')
            reqInput.value = value
        }

        mainSearchToExplore.reportValidity();
    })
}

mainNewsClamped();
onboardingModule();
wordToggleModule();
articlePreview();
mainNavModule()

if (document.getElementById('main-expand-corpora-list')) {
    import('./modules/expand-menu').then(({expandMenu}) => {
        expandMenu()
    })
}
